@import "../../scss/index.scss";

.signin-signup {
    .row{
        .header-banner{
            max-width: 983px;
            margin-top: -18px;
            background: transparent;

            img{
                width: 100%;
                margin-top: 18px;
            }
        }
    }
    &__left {
        overflow: none;
        min-height: 100vh;
        background-color: $primary-light;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            position: absolute;
            right: -35%;
        }

        div{
            z-index: 1;
            h2{
                display: flex;
                align-items: center;
                font-size: 30px;
                margin-bottom: 40px;
                font-weight: bold;

                svg{
                    margin-right: 20px;
                    font-size: 30px;
                    width: 40px !important;
                }
            }
        }
    }

    &__right{
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-bottom: 4%;
        filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.161));
        max-width: 983px;
        background-color: #F0F0F0;

        > div {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            text-align: center;

            .alert{
                background-color: #bee5eb;
            }

            img{
                width: 100px;;
                margin-bottom: 20px;
            }

            h2{
                font-weight: bold;
                font-size: 2rem;
                margin-bottom: 0;
                color: #6D716F;
                font-family: "Tungsten Narrow Semibold";
                font-weight: lighter;
            }

            h3{
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 30px;
                color: #6D716F;
            }
            hr{
                border-top: 1px solid #A2D65B;
            }

            form{
                .pass-wrapper {
                    position: relative;
                    display: flex;
                    margin-bottom: 14px;

                    i {
                        position: absolute;
                        top: 38%;
                        right: 10px;
                    }
                    i:hover {
                        color: darkgray;
                        cursor: pointer;
                    }
                  }
                  
                  

                .btn{
                    display: block;
                    width: 100%;
                    border-radius: 14px;
                    font-size: 1.3rem;
                    font-weight: bold;
                    padding: 10px 0;
                    margin-bottom: 20px;
                    font-family: "Avant Garde Std Demi";
    
                    svg{
                        margin-right: 10px;
                    }
                }
            }
            
        }
    }
}
.collapse{
    div{
        form{
            margin: 25px 0 10px 0;
        }
    }
}
.togglelink{
    width: 100%;
    display: inherit;
    cursor: pointer;
    margin: 10px 0;

    span{
        color: #FC8766;
        &:hover{
            color: #F36438;
            font-weight: bold;
        }
    }
}
.nav-tabs{
    a{
        width: 50%;
    }
}
@media (max-device-width: 485px){
    .signin-signup {
        &__right{
            padding: 30px 15px;
            > div {
                h2{
                    font-size: 2rem;
                    margin-bottom: 0;
                }
                h3{
                    font-size: .8rem;
                }
                button{
                    font-size: 1rem;
                }
            }
        }
    }
}

