@import "../../scss//index.scss";

.reset-pass{
    align-items: center;
    margin-top: 0;
    margin-bottom: 4%;
    -webkit-filter: drop-shadow(0 4px 6px rgba(0,0,0,.161));
    filter: drop-shadow(0 4px 6px rgba(0,0,0,.161));
    max-width: 983px !important;
    background-color: #f0f0f0;
    padding: 30px 15px;
    

    div{
        

        div{
            h3{
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 30px;
                color: #6d716f;
                font-size: 1rem !important;
                text-align: center;
            }

            div{
                img{
                    width: 100px;
                    margin-bottom: 20px;
                }
                h2{
                    font-size: 2rem;
                    margin-bottom: 0;
                    color: #6d716f;
                    font-family: "Tungsten Narrow Semibold";
                    font-weight: lighter;
                }
                hr{
                    border-top: 1px solid #a2d65b;
                }
                
            }
        }
    }

    form{
        button{
            display: block;
            width: 100%;
            border-radius: 50px;
            font-size: 20px;
            font-weight: bold;
            padding: 10px 0;
            margin-bottom: 20px;
        }
    }
    .alert{
        background-color: #bee5eb;
    }
    .text-center{
        a{
            color: #000;
        }
    }
    

}
.reset-page{
    .header-banner {
        max-width: 983px;
        margin-top: -18px;
        background: transparent;
    
        img{
            width: 100%;
            background-color: #4ec0e8;
            margin-top: -18px;
        }
    }
    .sec-footer{
        margin: 0 !important;
    }
}


@media (max-device-width: 485px){
    .reset-pass{
        max-width: 85% !important;
    }
    .reset-page{
        .header-banner {
            img{
                margin-top: 0;
            }
        }
    }
    
    .col-6 {
        flex: 100% !important;
        max-width: 100% !important;
    }
}