$primary: #1da0f2;
$primary-light: #71c9f8;
$primary-grey: #dee2e6;
$primary-dark-light: #192734;
$primary-dark: #15212b;

$font-primary: $primary;
$font-light: #fff;
$font-grey: $primary-grey;
$font-dark: #000;

$border-primary: $primary;
$border-light: #fff;
$border-grey: #38444d;
$border-dark: $primary-dark;

$background-light: #fff;
$background-grey-dark: $primary-dark;
$background-dark-light: $primary-dark-light;

$redis-back-azul: #4EC0E8;

$danger: #e0245e; 

