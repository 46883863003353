@import "../../scss//index.scss";

.Footer{
    text-align: center;
    align-items: center;
    color: #fff;
    font-size: .8rem;
    font-family: "Avant Garde Std Demi";
    width: 100%;

    .contactos{
        background-color: #494243;
        display: grid;
        justify-items: center;
        padding: 15px 27px;

        img{
            width: 100%;
            max-width: 350px;
        }
        span{
            margin: 5px 0;
        }
        .icos{
            img{
                width: 40px;
                margin: 5px;
            }
        }
        .reglamento{
            padding-top: 5px;
            a{
                color: white;
            }
        }
    }
    .copy{
        background-color: #A2D65B;
        display: inherit;
        justify-items: center;
        padding: 16px 27px;
        min-height: 49px;
        padding-bottom: 20px;

        span{
            float: left;
            margin: 3px 0;
        }
        a{
            float: right;
        }
        a span{

            color: #fff;
            &:hover{
                color: #007bff;
            }
        }
    }
}

@media (max-device-width: 485px){
    .copy{
        display: grid !important;
        justify-items: center;
        padding: 7px 27px;
        padding-bottom: 20px;

        span{
            float: unset !important;
            margin: 3px 0;
        }
        a{
            float: unset !important;
        }
    }
}

