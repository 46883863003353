@import "../../../scss/index.scss";

.basic-modal {
    .modal-dialog{
        width: auto;
        max-width: max-content;

    .modal-content{
        background-color: $background-light;
        border-radius: 25px;

        .modal-header{
            padding: 5px 15px 0 6px;
            border: 0px;

            .modal-title {
                

                span {
                    width: 15px;
                    height: 15px;
                    color: $primary;
                    margin-left: 10px;

                    &:hover {
                        cursor: pointer;
                    }
                    
                }

                img{
                    width: 35px;
                    cursor: pointer;
                }
            }
        }
        

        .modal-body{
            //padding: 20px 40px 40px 40px;
            padding: 0 10px 10px 10px;

            img{
                width: 100%;
                max-width: 400px;
                
            }
            div{
                text-align: center;
                font-weight: bold;
                font-size: .8rem;

                span{
                    margin: 0 5px;
                }
            }
        }
    }
    }
}

