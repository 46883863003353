@import "../../scss/index.scss";

.noPases{
    max-width: 350px;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 20px;

    img{
        background-color: transparent !important;
        box-shadow: unset !important;
    }
    > div{
        display: inline-block;
        vertical-align: middle;
    }
    .imgpase{
        width: 90px;
    }
    .txtpase{
        width: 192px;
        
        font-size: 2.4rem;
        line-height: 45%;
        text-align: left;
        padding: 0 0 0 10px;
        font-family: "Tungsten Narrow Semibold";
        font-weight: lighter;
        color: #6F716F;

        span{
            font-size: .9rem;
            color: #FC8766;
            font-family: "Avant Garde Std Demi";
        }
    }
    hr{
        margin-top: 0;
        border-top: 1px solid #29BAE3;
    }
}

.list-pases {
    background-color: #fff;
    
    .pase {
        padding: 10px 10px;
        border-bottom: 1px solid $primary-grey;
        margin: 0;

        .img-div {
            
            cursor: pointer;
            text-align: center;

            .qr {
                width: 75%;
                height: auto;
            }
            .spinner-border{
                margin-top: 15%;
            }
        }

        .body-pase {
            padding: 10px 15px;
            padding-left: 20px;
            

            .btn-delete{
                position: absolute;
                right: 30px;
                bottom: 20px;
                padding: .1rem .95rem;
                min-width: 79.59px;
            }

            .img-actividad{
                max-width: 142px;
                margin-top: -9px;
                margin-right: 20px;
            }
            .textto{
                padding: 0px 0 10px 0;
                width: auto;
            }
            .icoo{
                width: 70px;
            }
            > div{
                display: inline-block;
                vertical-align: middle;
            }

            .badge-status{
                float: right;
                width: auto;

                .badge{
                    min-width: 79.59px;
                    padding: 10px 11px;
                    margin-top: 13px;
                    color: white;
                    font-family: "Avant Garde Std Demi";
                }

                .badge-success{
                    background-color: #B8E672;
                }
            }

            div{
            > div{
                width: 300px;
            }

            .nombre-pase{
                font-size: 1.4rem;
                font-weight: bold;
               
            }
            button{
                margin-top: 10px;
                background-color: #206BFF;
            }
            
            label{
                margin-right: 20px;
            }
            div{
                label{
                    font-weight: bold;
                    font-size: 0.9rem;
                }
                span{
                    color: $font-dark;
                    font-size: 0.9rem;
                }
                .fecha{
                    font-weight: bold;
                }
            }
        }
        }
    }

    .paseHoy {
        .img-div{
            .qr {
                width: 100%;
            }
        }
        
        .body-pase {
            padding-top: 20px;
            div{
            > div{
                width: 300px;
            }
            div{
                
                label{
                    font-size: 1.3rem;
                }
                span{
                    font-size: 1.1rem;
                }
            }
        }
        }
      }
}

@media (max-device-width: 485px){
    .pase:first-child {
        padding: 10px 10px 10px 0 !important;
        .img-div{
            .qr {
                width: 60% !important;
            }
        }
        
        .body-pase {
            text-align: left;
            padding: 10px 0 0 15px !important;

            .btn-delete{
                right: 17px;
                bottom: 11px;
            }
            div{
            .nombre-pase{
                font-size: 1.2rem;
            }
            div{
                label{
                    font-size: .8rem !important;
                    margin: 0 8px 0 0 !important;
                }
                span{
                    font-size: .8rem !important;
                }
            }
        }
        }
      }
      .list-pases {
      .pase {
        padding: 10px 10px 10px 0 !important;
        
        .img-div{
            .qr {
                width: 40% !important;
            }
        }
        
        .body-pase {
            text-align: left;
            padding: 10px 0 0 12px !important;

            .btn-delete{
                right: 17px;
                bottom: 11px;
            }

            .textto{
                padding: 0px 0 10px 8px;

                div{
                    .dia-indicado{
                        font-size: .7rem !important;
                    }
                }
            }

            .img-actividad{
                box-shadow: unset;
                background-color:transparent;
            }

            div{
            .nombre-pase{
                font-size: .8rem !important;
            }
            div{
                label{
                    font-size: .8rem !important;
                    margin: 0 8px 0 0 !important;;
                }
                span{
                    font-size: .8rem !important;
                }
            }
        }
        }
      }
    }
}