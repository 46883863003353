

.caja-gen-codigo{
    

    .btn-generarcodigop{
        background-color: #A3D464;
        border-color: #A3D464;
        max-width: 300px;
        padding: .5rem 1rem;

        .img-qrr{
            margin-top: 0px;
        }
        .form-group{
            input{
                font-size: .9rem;
            }
        }
    }
}
.creditos-restantes{
    font-family: "Avant Garde Std Demi";
    color: #8A8A8A;
    margin-bottom: 5px;
    label{
        margin-bottom: 3px;
        width: 100%;
    }
    span{
        .badge{
            padding:5px 5px 2px 3px;
            font-size: 1rem;
        }
    }
}

@media (max-device-width: 485px){
    .modal-qr{
        .modal-dialog{
            max-width: 95%;
        }
    }
    .caja-gen-codigo{
        .btn-generarcodigop{
            max-width: 303px;
            padding: .5rem .1rem;
            .img-qrr{
                box-shadow: unset;
                background-color: transparent;
            }
        }
    }
    
}