@import "../../scss/index.scss";



form{
    .pass-wrapper {
        position: relative;
        display: flex;
        margin-bottom: 14px;

        i {
            position: absolute;
            top: 38%;
            right: 10px;
        }
        i:hover {
            color: darkgray;
            cursor: pointer;
        }
    }
    .btn{
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        padding: 15px;
        border-radius: 50px;
        margin-top: 20px;

        .spinner-border {
            width: 25px;
            height: 25px;
        }
    }

    .grupo-nacimiento{
        padding:0 5px;
    
        .col{
            padding: 0;
        }
    }
    .css-2b097c-container .css-yk16xz-control{
        background-color: #f0f0f0;
        font-size: 1rem;
        border-bottom: 1px solid #dee2e6;

        .css-g1d714-ValueContainer{
            padding: 2px 8px 2px 0;
        }
    }
    
}

@media (max-device-width: 485px){
    .css-2b097c-container .css-yk16xz-control{
        font-size: 1rem !important;
    }
}

