@import "../../scss//index.scss";
 
.left-menu {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-height: 100vh;
    border-right: 1px solid $primary-grey;

    .divhr{
        width: 80%;
        margin: 0 auto;
        height: 1px;
        border-bottom: 1px solid lightgray;
    }
    .logo{
        width: 100%;
        max-width: 250px;
        height: auto;
        padding: 65px 0 86px 0;
        margin: 0 auto;
        margin-bottom: 0px;
        margin-top: 29px;
    }
    .cityparks{
        width: 100%;
        max-width: 250px;
        height: auto;
        padding: 10px 0 30px 0;
        margin: 0 auto;
    }
    >div{
        display: -moz-inline-grid;
        display: -ms-inline-grid;
        display: inline-grid;
        width: 235px;
        
        div{
            width: 100%;
            border-bottom: 1px solid #4EC0E8;
            padding: 15px 10px;
            border-radius: 5px;

            &:hover{
                a{
                    color: #4EC0E8;
                }
                background: RGB(78, 192, 232, .1);
                cursor: pointer;
            }

            .ico-menu{
                width: 40px;
                margin-right: 10px;
            }
            a {
                font-size: 20px;
                color: $font-dark;
                font-weight: bold;
                width: 100%;
                height: 100%;
                display: block;
                margin: 0;
        
                &:hover {
                    text-decoration: none;
                    color: $primary;
                }
        
                svg {
                    margin-right: 20px;
                    width: 30px !important;
                }
            }
        }
        .salir-btn{
            border-bottom: 1px solid #F68664;
            &:hover{
                a{
                    color: #F68664;
                }
                background: RGB(246, 134, 100, .1);
            }
        }
        
    }
    

    button{
        width: 80%;
        border-radius: 50px;
        font-size: 18px;
        padding: 10px;
        margin-top: 30px;
        font-weight: bold;
    }
}



@media (max-device-width: 485px){
    .left-menu {
        padding-right: 5px;
        text-align: center;

        .logo{
            padding: 15px 0 30px 0;
            width: 100%; 
        }

        a {
            font-size: 15px;
            
            svg {
                margin-right: 15px;
                margin-left: 15px;
                width: 20px !important;
            }

        }
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
    .left-menu {

        a {
            font-size: 1.1rem;
            
            svg {
                margin-right: 8px;
                width: 18px !important;
            }

        }
    }
}