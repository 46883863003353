.tarjeta{
    .mw-30{
        max-width: 31%;
    }
}

.caja-gen-codigo{
    

    .btn-generarcodigo{
        background-color: #A3D464;
        border-color: #A3D464;
        max-width: 213px;
        padding: .5rem 1rem;

        .img-qrr{
            margin-top: 0px;
        }
    }
}

.acciones-metros{
    cursor: pointer;
}




@media (max-device-width: 485px){
    .modal-qr{
        .modal-dialog{
            max-width: 95%;
        }
    }
    .caja-gen-codigo{
        .btn-generarcodigo{
            .img-qrr{
                box-shadow: unset;
                background-color: transparent;
            }
        }
    }
    .divisor{
        h5{
            font-size: .7rem;
        }
    }

    .table-responsive{
        .tbl-codigos-qr{
            font-size: .7rem;
        }
    }
    
}

