@import "../../scss//index.scss";

.user{

    &__pases {
        .head-acomp{
            background-color: honeydew;
            width: 100%;
            min-height: 70px;
            .contenedor-add{
                float: right;
                display: inline-grid;
                justify-items: center;
                font-family: "Avant Garde Std Demi";
                padding: 0 5px;
                color: #68BC68;
                font-size: .9rem;
                margin-top: 8px;
                cursor: pointer;
                .addAcompanante{
                    max-width: 20px;
                    margin: 5px 10px;
                    cursor: pointer;
                }
                &:hover{
                    
                    color: #069C06;
                }
            }
            
            .contenedor-atras{
                float: left;
                display: inline-grid;
                justify-items: center;
                font-family: "Avant Garde Std Demi";
                padding: 0 5px;
                color: #68BC68;
                font-size: .9rem;
                margin-top: 8px;
                .irAtras{
                    max-width: 20px;
                    margin: 5px 10px;
                    cursor: pointer;
                }
                &:hover{
                    color: #069C06;
                }
            }

        }
        
        
        h2{
            background-color: honeydew;
            margin-bottom: -44px;
            padding: 8px 0 0 0;
            color: #46BFEA;
            font-family: "Avant Garde Std Demi";
            font-size: 1.9rem;
            margin: 0;
            text-align: center;
        }
        .table{

            th,td{
                padding: .8rem 1.5rem !important;
            }
            td{
                .btn{
                    margin: 0 7px;
                    padding: 2px 7px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-device-width: 485px){
    .user{

        &__pases {

            .head-acomp{
                min-height: 50px;
                .contenedor-add{
                    max-width: 120px;
                    text-align: center;

                    img{
                        display: none;
                    }

                }
                .contenedor-atras{
                    max-width: 70px;
                    text-align: center;

                    img{
                        display: none;
                    }
                }
            }

            h2{
                font-size: 1.5rem;
                
                margin-bottom: 0px;
                padding: 8px 0 0 0;
            }
            .addAcompanante{
                background-color: transparent;
                box-shadow: unset;
                max-width: 37px;
            }

            .table{
    
                th,td{
                    padding: .75rem!important;
                }
                
            }
        }
    }
}