@import "../../../scss/index.scss";

.pase-modal{
    .modal-content { 
        background-color: #F0F0F0;
        border-radius: 25px;
        overflow: none;

        input.form-control, .react-datepicker-wrapper .react-datepicker__input-container input, .css-2b097c-container .css-yk16xz-control{
            background-color: #F0F0F0; 
            border-bottom: 1px solid #dee2e6;
        }

        .modal-header {
            border-bottom: 1px solid #46BFEA;
            padding: 25px 15px 0 15px;
            width: 100%;

            .modal-title {
                color: #46BFEA;
                font-family: "Avant Garde Std Demi";
                width: 100%;

                img{
                    width: 35px;
                }

                .cerrar{
                    position: absolute;
                    right: 15px;
                    cursor: pointer;
                }

                span {
                    width: 15px;
                    height: 15px;
                    color: $primary;
                    margin-left: 10px;

                    &:hover {
                        cursor: pointer;
                    }
                    
                }
                label{
                    margin-left: 20px;
                    vertical-align: text-top;
                }
            }
        }
        .modal-body {
            .css-1wa3eu0-placeholder{
                top: 59%;
            }
            .leyenda{
                padding:10px 0 20px 0;
            }

            .form-group {
                padding-bottom: 20px;
        
                input {
                    font-size: 1.2rem;
                }
            }
            button{

                .spinner-border {
                    width: 25px;
                    height: 25px;
                }
            }
            .btn-submitt{
                width: 100%;
                font-size: 20px;
                font-weight: bold;
                padding: 15px;
                border-radius: 50px;
                margin-top: 20px;
            }

            form{

                .updt{
                    .nuevo-aco{
                        width: 200px;
                        cursor: pointer;
                    }
                }
                //***CSS PARA AJUSTAR MULTISELECT
                .css-2b097c-container{
                    .css-26l3qy-menu{
                        text-align: left;
                    }
                    .css-1pahdxg-control{
                        .css-g1d714-ValueContainer{
                            div{
                                margin: 0 1px !important;

                                .css-1rhbuit-multiValue{
                                    .css-12jo7m5{
                                        font-size: 69% !important   ;
                                    }
                                }
                            }
                        }
                        .css-1hwfws3{
                            div{
                                margin: 0 1px !important;
                                .css-1rhbuit-multiValue{
                                    .css-12jo7m5{
                                        font-size: 69% !important   ;
                                    }
                                }
                            }
                        }
                    }
                    .css-yk16xz-control{
                        .css-1hwfws3{
                            div{
                                margin: 0 1px !important;
                            }
                        }
                        .css-g1d714-ValueContainer{
                            div{
                                margin: 0 1px !important;
                            }
                        }
                        font-size: 1.2rem;
                    }
                    
                } 
                //****
                .updt{
                    text-align: center;
                    .actualizar{
                        width: 200px;
                    }
                }
                
            }
        }
        .css-12jo7m5{
            font-size: 69% !important;
            text-overflow: unset !important;
        }
    }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
    .pase-modal{
        .modal-content{
            .modal-header{
                .modal-title {
                    span{
                        align-items: flex-start;
                        display: flex;
                        position: absolute;
                    }
                    label{
                        margin-left: 10px;
                        vertical-align: text-top;
                    }
    
                }
                .h4{
                    font-size: 1.2rem;
                }
            }
    
        }
    }
}

@media (max-device-width: 485px){
    .pase-modal{
        .modal-content{
            .modal-header{
                .modal-title {
                    span{
                        align-items: flex-start;
                        display: flex;
                        position: absolute;
                    }
                    label{
                        margin-left: 10px;
                        vertical-align: text-top;
                    }
    
                }
                .h4{ 
                    font-size: 1.2rem;
                }
            }

            .modal-body {
                .form-group {
            
                    input {
                        font-size: 1rem;
                    }
                }
                .btn-submitt{
                    font-size: 1.2rem;
                }
            }
    
        }
    }
}