@import "../../scss//index.scss";
.ico-titulos{
    max-width: 25px;
    margin: 0px;
    margin-right: 8px;
    margin-top: 0px !important;
    box-shadow: none !important;
    background-color: transparent !important;
}
.sec-salir-pasaportes{
    font-family: "Avant Garde Std Demi";

    .caja-salirr{
        width: 122px;
        background-color: #FDC43C;
        margin-right: 15px;
        margin-top: 4px;
        color: white;
        padding: 3px;
        border-radius: 5px;
        cursor: pointer;

        .salirr{
            margin-top: 0px;
            max-width: 30px;
            box-shadow: none !important;
            background-color: transparent !important;
        }
    }
    .movil-btn-o{
        display: none;
    }
    .caja-ver-pases{

    }

}
.user{

    &__pases {
        
        
        .divisor{
           
            margin-top: 5px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            margin: 8px 17px;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-family: "Avant Garde Std Demi";
            color: #686868;

            h5{
                margin-bottom: 0px;
                a{
                    color: #686868;
                }
            }
            .ico-titulos{
                max-width: 30px;
                margin: 0px;
                margin-right: 8px;
            }
        }
        .contentPasaportes{

            h5{
                padding: 20px;
                text-align: center;
            }
            .tarjeta{
                
                border-radius: .25rem;
                margin: 1px;
                padding: 15px 0;

                .mw-30{
                    max-width: 31%;
                }

                .text-muted{

                    label{
                        font-family: "Avant Garde Std Demi";
                        color:#686868;
                    }
                    .rowNombre{
                        max-width: 60%;
                        margin: 0 auto;
                        text-align: center;
                        border-bottom: 1px solid #FD8775;
                    }
                    .vigencia{
                        background: #A3D464;
                        max-width: 60%;
                        margin: 0 auto;
                        margin-top: 10px;
                        padding: 13px 12px 2px 12px;
                        border-radius: 10px;
                        text-align: center;
                    }
                }
                .fotoiden{
                    margin: 0;
                }
            }
        }
    }
}

@media (max-device-width: 485px){
    .sec-salir-pasaportes{
        font-size: .7rem;
        
        .caja-salirr{
            width: 95px;
        }
        .movil-btn-o{
            display: block;
            width: 70px;
        }
    }
    .user__pases .contentPasaportes .tarjeta{
        padding-left: 0px !important;
        font-size: .8rem;

        .col{
            padding-left: 5px;
            padding-right: 5px;
        }
        .text-muted{

            .rowNombre{
                max-width: 80%;
            }
        }
    }
    .user__pases .contentPasaportes .tarjeta .fotoiden{
        box-shadow: none;
    }

    .user{

        &__pases {
            
            
            .divisor{

                .ico-titulos{
                    box-shadow: none !important;
                    background-color: transparent !important;
                }
            }
        }
    }
}