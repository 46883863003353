@import "../../scss//index.scss";

.user{

    &__pases {
        background-color: #fff;
        
        img{
            width: 100%;
            margin-top: -29px;
        }
        nav{
            a{
                color: $primary;
                font-weight: bold;
            }
        }
        h3 {
            padding: 15px;
            text-align: center;
            font-size: 1.6rem;
            font-weight: bold;
            //border-bottom: 1px solid $primary-grey;
        }
        .leyenda{
            width: 100%;
            max-width: 400px;
            font-size: 1.2rem;
            font-weight: bold;
            margin: 0 auto;
            padding: 20px 10px;
            text-align: center;
        }
    }
    
}

@media (max-device-width: 485px){
    .user{

        &__pases {
            h3 {
                font-size: 1.2rem;
                
            }
            .leyenda{
                font-size: 1.1rem;

            }
            img{
                width: 100%;
                box-shadow: 0 0 5px 0px #4EC0E8;
                background-color: #4EC0E8;
                margin-top: -22px;
            }

            
        }
    }

}