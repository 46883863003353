.svg-blanco{
    color: #000000;
}
.botones-pasapor{

    .col-sm-6{
        
        .txt-leyenda-pase{
            font-size: .9rem;
            font-family: "Avant Garde Std Demi";
            color: #7A7A7A;
            min-height: 125px;

            b{
                color: #000000;
            }
        }
        .titulo-leyv{
            font-family: "Avant Garde Std Demi";
            color: #686868;
        }
        button{
            font-family: "Avant Garde Std Demi";
            padding: 2rem 2.8rem;
            min-width: 288px;
        }
        .btn-personal{
            
            background-color: #EE6C5B;
            color: white;
        }
        .btn-estacionamiento{
            background-color: #707372;
            color: white;
        }
        .ico-parkimovil{
            max-width: 198px;
        }
        .ico-encuestas{
            max-width: 70px;
        }
        
    }
}
.divisor{

    h5{
        span{
            color: #53C1E6;
        }
    }
}

@media (max-device-width: 485px){
    .botones-pasapor{

        .col-sm-6{
            .txt-leyenda-pase{
                font-size: .7rem;
                min-height: auto;
            }
        }
    }
}


