@import "./scss/index.scss";

body {
  margin: 0;
  font-family: 'Avant Garde Std';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $redis-back-azul; 
  color: $primary-dark;
  padding: 100px;

  div{
    
    max-width: 1180px;
    margin: 0 auto;
  }
  > div{
    background-color: $background-light;
  }
  .fade{
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.3);;
  }
}

.modal-body{
  width: 100%;
}




.btn {
  box-shadow: none !important;
}

.btn.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:hover{
    background-color: rgba(26, 145, 218, 1);
  }
}

.btn.btn-outline-primary {
  border-color: #4EC0E8;
  color: #4EC0E8;
  &:hover,
  &:active,
  &:focus {
    background-color: rgba(29, 261, 241, 0.1) !important;
  }
}

input.form-control,
textarea.form-control {
  background-color: #f0f0f0;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid $primary-grey;
  color: $font-dark !important;

  &:focus {
    background-color: $background-light;
    box-shadow: none;
    border-bottom: 1px solid $primary-grey;
  }
}

form{
  .css-2b097c-container{
    .css-yk16xz-control{
      font-size: 1rem;
    }
  } 
  background-color: #f0f0f0;
}


.react-datepicker-wrapper {
  width: 100%;
  height: 38px;

  .react-datepicker__input-container {
    height: 100%;

    input {
      background-color: $background-light;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid $border-grey;
      color: $font-dark !important;
      width: 100%;
      height: 100%;
      padding: 0 12px;
    }
  }
}

.css-2b097c-container{
  width: 100%;
  height: 38px;

  .css-yk16xz-control{
    background-color: $background-light;
    color: $font-dark !important;
    padding: 0 12px;
    border: 0;
    border-bottom: 1px solid $border-grey;
    

    .css-1uccc91-singleValue{
      color: $font-dark !important;
    }
  }

  .css26l3qy-menu{
    background-color: $background-dark-light !important;
  
    .css-4ljt47-MenuList{
      color: $background-dark-light !important;
      
      .css-yt9ioa-option{
        color: $background-dark-light !important;
      }
  
    }
  }

}
.css-yt9ioa-option{
  color: #15212b !important;

  &:hover{
    color: darkcyan !important;
  }
}


@media (max-device-width: 485px){
  body{
    padding: 18px 0 0 0;
  }
  form{

  }
  form{
    .css-2b097c-container{
  
      .css-yk16xz-control{
  
        font-size: 1rem;
  
      }
    
    }
  }
  
}

@media (max-device-width: 850px) and (orientation: landscape) {
  body {
    padding: 0px;
  }
}


