@import "./colors";

@font-face {
    font-family: "Avant Garde Std";
    src: url("../assets/font/ITCAvantGardeStd-Bk.ttf");
  }

@font-face {
    font-family: "Avant Garde Std Demi";
    src: url("../assets/font/ITCAvantGardeStd-Demi.ttf");
  }

@font-face {
    font-family: "Tungsten Narrow Semibold";
    src: url("../assets/font/TungstenNarrow-Semibold.ttf");
  }

  