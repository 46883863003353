@import "../../scss/index.scss";

.basic-layout {
    padding: 0;
    max-width: 100% !important;
    margin: 0 15px;

    &__menu {
        padding-right: 0 !important;
        padding-left: 5px !important;
    }
    .col-3{
        background: linear-gradient(#FFFFFF 300px, #f0f0f0 300px);
    }

    &__content {
        height: 100vh;
        overflow-y: scroll;
        padding: 0 !important;
        border-right: 1px solid $primary-grey;
        display: inline-table;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .menu-movil{
        display: none;
        position: fixed;
        bottom: 0px;
        text-align: center;
        width: 100%;
        max-width: 1140px;
        background-color:#F0F0F0;
        padding: 5px 0px;
    }

    
}

@media (max-device-width: 485px){
    .basic-layout {
        padding-right: 0 !important;
        margin: 0;
        padding-left: 0px !important;

        &__menu {
            padding-left: 0 !important;
        }

        &__content{
            min-height: 50vh;
            height: auto;
        }

        .row{
            margin-right: 0;
            padding-left: 15px !important;
            .col-3{
                display: none;
            }
            .col-9 {
                flex: 100%;
                max-width: 100%;
            }
        }
        .menu-movil{
            display: flex;
            margin: 0;
            padding-left: 0 !important;
        }
        .footer-sec{
            padding-left: 15px !important;
            margin-bottom: 8vh;
        }
        
    }
    
}

@media (max-device-width: 770){
    .basic-layout {
        &__menu {
            padding-left: 0 !important;
        }
        


        
    }
}

@media (max-device-width: 850px) and (orientation: landscape) {
    .basic-layout {
        padding-right: 0 !important;
        margin: 0;
        padding-left: 0px !important;
        
        .row{
            margin-right: 0;
            padding-left: 15px !important;
            .col-3{
                display: none;
            }
            .col-9 {
                flex: 100%;
                max-width: 100%;
            }
        }
        .menu-movil{
            display: flex;
            margin: 0;
            padding-left: 0 !important;
        }
    }
    
}

