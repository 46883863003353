.back-metropases{
    background-color: #A2D564;
    
}
.back-pasapets{
    background-color: #FDC625;
}
.back-pasavantes{
    background-color: #55C0E5;
}
.btn-general{
    max-width: 130px;
    width: 100%;
    text-align: center;
    color: white;
    font-family: "Avant Garde Std Demi";
    display: inline-block;
    border-radius: 10px;

    label{
        cursor: pointer;
        margin-top: 10px;
    }
}
.ico-bigs{
    width: 100%;
    max-width: 80px;
    margin-top: 0px !important;
}
.btn-pasaportes{
    max-width: unset;
    width: auto;
    padding: 4px 8px;
    min-width: 150px;

    img{
        max-width: 40px;
    }
}

.botones-pasapor{
    margin-left: auto !important;
    margin-right: auto !important;
    
    margin: 0 auto;

    div{
        .mh-pase-l{
            min-height: 85px;
        }
        .txt-leyenda-pase{
            font-size: .9rem;
            font-family: "Avant Garde Std Demi";
            color: #7A7A7A;
        }
        .titulo-leyv{
            font-family: "Avant Garde Std Demi";
            color: #686868;
        }
    }
}

@media (max-device-width: 485px){
    .ico-bigs{
        box-shadow: none !important;
        background-color: transparent !important;
    }
    .ico-bigs{
        box-shadow: none !important;
        background-color: transparent !important;
    }
    .botones-pasapor{
        .col{
            padding-right: 2px ;
            padding-left: 2px ;
        }
        div{
            .mh-pase-l{
                min-height: auto;
            }
        }
    }
}