.t2botones{
    max-width: 400px;
}
.btn-general{
    span{
        color: #F2F2F2;
        font-size: .7rem;
        line-height: 13px;
        display: block;
        margin-bottom: 7px;
    }
}
.solo-servicios{
    max-width: 180px;
}

.ico-bigs-servicios{
    margin-top: 5px !important;
}