@import "../../scss//index.scss";

.movil-menu{
    display: initial;

    a {
        font-size: 10px;
        color: #707271;
        font-weight: bold;
        display: inline-grid;
        justify-items: center;
        margin: 0 6px;

        &:hover {
            text-decoration: none;
            color: $primary;
        }

        .ico-menu {
            
            width: 30px !important;
        }

    }
    .salir{
        color: #FB8870;
    }
}