@import "../../../scss/index.scss";

.Acompanantes{
    .modal-content{
        background-color: #F0F0F0; 
        border-radius: 25px;
        overflow: none;

        input.form-control, .react-datepicker-wrapper .react-datepicker__input-container input, .css-2b097c-container .css-yk16xz-control{
            background-color: #F0F0F0; 
            border-bottom: 1px solid #dee2e6;
        }

            .modal-header{
                border-bottom: 1px solid #46BFEA;
                padding: 25px 15px 0 15px;
                width: 100%;

                .modal-title{
                    color: #46BFEA;
                    font-family: "Avant Garde Std Demi";
                    width: 100%;

                    img{
                        width: 35px;
                    }
                    label{
                        margin-left: 20px;
                        vertical-align: text-top;
                    }
                    .cerrar{
                        position: absolute;
                        right: 15px;
                        cursor: pointer;
                    }
                }
            }

            .modal-body {
                form{
                    .css-2b097c-container{
                        .css-yk16xz-control{
                            font-size: 1.2rem;
                        }
                    }
                    .updt{
                        .nuevo-aco{
                            width: 200px;
                            cursor: pointer;
                        }
                    }

                }  

                .leyenda{
                    padding:10px 0 20px 0;
                }
    
                .form-group {
                    padding-bottom: 20px;
            
                    input {
                        font-size: 1.2rem;
                    }
                }
                button{
    
                    .spinner-border {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
    }
    
}

@media (max-device-width: 485px){
    .Acompanantes{
        .modal-content{
            .modal-header{

                .modal-title{
                    font-size: 1rem;
                }
            }

            .modal-body {

                .form-group {
            
                    input {
                        font-size: 1rem;
                    }
                }
                button{
                    font-size: 1.2rem;
                }

            }
        }
    }
}